import React, { Component } from 'react';
import './progress-bar.css';


class ProgressBar extends Component {
  getSkill = (progress) => {
    let level = '';
    
    switch(true) {
      case progress > 90:
        level = 'Expert';
        break;

      case progress > 80:
        level = 'Senior'
        break;

      case progress > 70:
        level = 'Mid'
        break;

      case progress > 50:
        level = 'Junior';
        break;

      case progress > 0:
        level = 'Entry';
        break;

      default:
        level = 'Value not set';
        break;
    }

    return level;
  }

  render() {
    const skillLevel = this.props.displaySkillLevel
      ? this.getSkill(this.props.progress)
      : this.props.progress;

    return (
      <div className="flex progress-bar-container">
        {this.props.label ? <label>{this.props.label}</label> : null}
        <div className="progress-bar">
            <div className="flex progress" style={this.props.progress ? {width: this.props.progress + '%'} : {width: '0%'}}>
              { !this.props.displaySkillLevel ? `${this.props.progress}%` : skillLevel }
            </div>
        </div>
      </div>
    );
  }
}

export default ProgressBar;
