import React, { Component } from 'react';
import './heading.css';

class Heading extends Component {
  render() {
    let size = 'medium';
    let iconSize = '';

    if(this.props.size) {
        switch(this.props.size) {
            case 'x-small':
                size = 'x-small';
                iconSize = 'fa-xs';
                break;

            case 'small':
                size = 'small';
                iconSize = 'fa-sm';
                break;

            case 'large':
                size = 'large';
                iconSize = 'fa-lg';
                break;

            case 'x-large':
                size = 'x-large';
                iconSize = 'fa-2x';
                break;

            default:
              break;
        }
    }
      
    let classes = this.props.size ? ['heading-text', size] : ['heading-text', size];
    classes = this.props.emphasize ? [...classes, 'emphasize'] : [...classes];
    let iconClasses = iconSize ? [this.props.icon, iconSize, 'icon'] : [this.props.icon, 'icon'];
    
    return (
      <div className="flex heading">
      {this.props.icon ? <i className={iconClasses.join(' ')}></i> : null}
        <div className={classes.join(' ')}>{this.props.heading}</div>
      </div>
    );
  }
}

export default Heading;