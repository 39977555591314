import React, { Component } from 'react';
import './app.css';

import ResumeView from '../views/resume-view/resume-view';

class App extends Component {
  render() {
    return (
      <div className="app">
        <ResumeView />
      </div>
    );
  }
}

export default App;
