import React, { Component } from 'react';
import './nav-bar.css';

// NPM Imports
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';

// Images
import { ReactComponent as Logo } from '../images/static/logo.svg';

class NavBar extends Component {
  constructor(props) {
    super();
    this.printButton = React.createRef();
    this.showPrint = this.showPrint.bind(this);
  }

  showPrint = () => {
    window.print();
  }

  render() {
    return (
      <div className="flex nav-bar">
        <a className="flex logo" href="http://darrenvardon.com">
          <Logo />
          <div className="flex logo-slogan">
            <div className="site">DarrenVardon.com</div>
            <div className="slogan">Learn. Adapt. Grow</div>
          </div>
        </a>

        <div className="flex nav-menu">
          <Tooltip title="Print Resume" aria-label="Print Resume" placement="left" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
            <div ref={this.printButton} className="flex flex-center nav-item" onClick={ this.showPrint }>
              <i className="fas fa-print"></i>
              <div>Print</div>
            </div>
          </Tooltip>
        </div>
      </div>
    );
  }
}

export default NavBar;