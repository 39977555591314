import React, { Component } from 'react';
import './resume-view.css';

// Components
import Footer from '../../footer/footer';
import Heading from '../../heading/heading';
import ProgressBar from '../../progress-bar/progess-bar';
import NavBar from '../../nav-bar/nav-bar';


// Images
import ProfilePhoto from '../../images/profile-photo/profile-photo';

import StaticData from '../../data.json';

class ResumeView extends Component {
  render() {
    return (
      <div className="resume-view">

        <NavBar />

        <div className="flex content">
          <div className="container side-container">
              <div className="print-only heading-text large">{ StaticData.User.name }</div>
              <ProfilePhoto />
              <Heading size="small" icon="fa fa-briefcase" heading={StaticData.User.job} />
              <Heading size="small" icon="fa fa-home" heading={StaticData.User.location} />
              <Heading size="small" icon="fa fa-envelope" heading={StaticData.User.email} />
              <Heading size="small" icon="fa fa-phone" heading={StaticData.User.phone} />
              <Heading size="small" icon="fa fa-globe" heading={StaticData.User.site} />
              <div className="divider"></div>

              <div className="measurables skills">
                <Heading size="large" icon="fa fa-star" heading="Skills" emphasize={true} />
                {StaticData.Skills.sort((a, b) => b.value - a.value).map((skill, i) => <ProgressBar key={ i } label={ skill.name } progress={ skill.value } displaySkillLevel={true} />)}
              </div>
              
              <div className="divider"></div>

              <div className="measurables soft-skills">
                <Heading size="large" icon="fas fa-chart-bar" heading="Soft Skills" emphasize={true} />
                {StaticData.SoftSkills.sort((a, b) => b.value - a.value).map((skill, i) => <ProgressBar key={ i } label={ skill.name } progress={ skill.value } />)}
              </div>

              <div className="divider"></div>

              <div className="measurables">
                <Heading size="large" heading="Backend Technologies" emphasize={true} />
                <div className="bullets">
                  { StaticData.Technologies.backend.map((item, i) => <div key={ i } className="flex bullet"><i className="fas fa-xs fa-dice-d20"></i> <div>{ item }</div> </div>) }
                </div>
              </div>

              <div className="divider"></div>

              <div className="measurables">
                <Heading size="large" heading="Frontend Technologies" emphasize={true} />
                <div className="bullets">
                  { StaticData.Technologies.frontend.map((item, i) => <div key={ i } className="flex bullet"><i className="fas fa-xs fa-dice-d20"></i> <div>{ item }</div> </div>) }
                </div>
              </div>

              <div className="divider"></div>

              <div className="measurables">
                <Heading size="large" heading="Database Technologies" emphasize={true} />
                <div className="bullets">
                  { StaticData.Technologies.database.map((item, i) => <div key={ i } className="flex bullet"><i className="fas fa-xs fa-dice-d20"></i> <div>{ item }</div> </div>) }
                </div>
              </div>

              <div className="divider"></div>

              <div className="measurables">
                <Heading size="large" heading="Cloud Technologies" emphasize={true} />
                <div className="bullets">
                  { StaticData.Technologies.cloud.map((item, i) => <div key={ i } className="flex bullet"><i className="fas fa-xs fa-dice-d20"></i> <div>{ item }</div> </div>) }
                </div>
              </div>

              <div className="divider"></div>

              <div className="measurables">
                <Heading size="large" heading="Hobbies" emphasize={true} />
                <div className="">
                  { StaticData.Hobbies.join(', ') }
                </div>
              </div>

          </div>

          <div className="experiences">
            <div className="container">
              <Heading size="x-large" heading="Summary" emphasize={true} />
              { StaticData.Summary.map((paragraph, i) => <p key={ i }>{ paragraph }</p>) }
            </div>

            
              { StaticData.Experiences.map((experience, i) => {
                return (
                  <div key={i} className="container">
                    <Heading size="x-large" icon={experience.icon} heading={experience.title} emphasize={true} />
                    { experience.items.map((item, j) => {
                      const startDate = item.date.start;
                      const endDate = item.date.end ? item.date.end : 'Present';
                      const spacer = startDate && endDate ? <div>&nbsp;-&nbsp;</div> : null;
                      return (
                        <div key={i + '-' + j} className={item.isHiddenOnResume ? "experience-item hide-from-print" : "experience-item"}>
                          <Heading heading={item.heading} />
                          <Heading size="small" heading={item.subHeading} />
                          <div className="flex date-range">
                            <i className="fas fa-calendar-alt" />
                            <div className="flex">
                              { startDate ? <div className="start-date">{ startDate }</div> : null}
                              { spacer }
                              { endDate ? <div className={ endDate === 'Present' || !startDate ? 'end-date present' : 'end-date'}>{ endDate }</div> : null }
                            </div>
                          </div>
                          <div className="experience-summary">{ item.description }</div>
                          <div className="bullets">
                            { (item.bullets || []).map((bullet, i) => <div key={ i } className="flex bullet"><i className="fas fa-xs fa-dice-d20"></i> <div>{ bullet }</div> </div>) }
                          </div>
                          { j < experience.items.length - 1 ? <div className="divider"></div> : null }
                        </div>
                      );
                    }) }
                  </div>
                );
              }) }
          </div>
         
        </div>       

        <Footer description={StaticData.Footer.description} socialMedia={StaticData.Footer.socialMedia} />

      </div>
    );
  }
}

export default ResumeView;
