import React, { Component } from 'react';
import './footer.css';


class Footer extends Component {
  render() {
    return (
      <div className="flex footer">
        <div>{this.props.description}</div>

        <div className="social-media">
          {(this.props.socialMedia || []).map((account, i) => <a key={i} className={account.icon} href={account.url} target="_blank" rel="noopener noreferrer"><span></span></a>)}
        </div>

        <div>Powered by <strong><em>v-flow.css</em></strong></div>
      </div>
    );
  }
}

export default Footer;