import React, { Component } from 'react';
import './profile-photo.css';


class ProfilePhoto extends Component {
  render() {
    return (
      <div className="flex profile-photo"> 
        <div className="flex caption">
          <div className="caption-text">Darren Vardon</div>
        </div>
      </div>
    );
  }
}

export default ProfilePhoto;